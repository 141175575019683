import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Seo from "../components/Seo";
import Article from "../components/Article";
import Headline from "../components/Article/Headline";
// import Page from "../components/Page";
import { ThemeContext } from "../layouts";

const _ = require("lodash");
const countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const TadigTemplate = props => {
  const {
    data: { allE212Csv, allTs25ExtractCsv }
  } = props;

  const { tadig } = props.pageContext;

  let operator = [];
  let countryName = [];
  let frequency = [];

  for (let item of allE212Csv.nodes) {
    operator.push(item.Network_name);
    let country = countries.getName(item.Country_code__ISO_CC3_, "en");
    if (country) countryName.push(country);
  }
``
  for (let item of allTs25ExtractCsv.nodes) {
    operator.push(item.Network);
    let country = countries.getName(item.Country_Initials, "en");
    if (country) countryName.push(country);
    frequency.push(item.Net._Type);
  }

  operator = _.uniq(operator);
  let country = countryName[0] || "N/A";
  frequency = _.sortBy(frequency);

  // Manual corrections
  if(tadig === 'UGATL'){
    operator = operator.filter(item => item !== 'TANGERINE')
  }

  const page = {
    frontmatter: {
      title: `TADIG ${tadig} - ${operator[0]}`,
      description: `{operator[0]} has a Transferred Account Data Interchange Group (TADIG) of {tadig}.`
    },
    fields: {
      slug: `/tadig/${tadig}`
    }
  };

  return (
    <React.Fragment>
      <ThemeContext.Consumer>
        {theme => (
          <Article theme={theme}>
            <header>
              <Headline title={`TADIG ${tadig} - ${operator[0]}`} theme={theme} />
            </header>

            <div className="bodytext">
              <p>
                {operator[0]} has a Transferred Account Data Interchange Group (TADIG) of{" "}
                <strong>{tadig}</strong>.
              </p>

              {operator.length > 1 && (
                <div>
                  <h3>Operator Names</h3>
                  <ul>
                    {operator.map(item => {
                      return <li key={item}>{item}</li>;
                    })}
                  </ul>
                </div>
              )}

              {countryName && (
                <div>
                  <h3>Country</h3>
                  <p>{country}</p>
                </div>
              )}

              {frequency.length > 0 && (
                <div>
                  <h3>Operating Frequencies</h3>
                  <ul>
                    {frequency.map(item => {
                      return <li key={item}>{item}</li>;
                    })}
                  </ul>
                </div>
              )}
            </div>

            <style jsx>{`
              .bodytext {
                animation-name: bodytextEntry;
                animation-duration: ${theme.time.duration.long};

                :global(h2),
                :global(h3) {
                  margin: 1.5em 0 1em;
                }

                :global(h2) {
                  line-height: ${theme.font.lineHeight.s};
                  font-size: ${theme.font.size.l};
                }

                :global(h3) {
                  font-size: ${theme.font.size.m};
                  line-height: ${theme.font.lineHeight.m};
                }

                :global(p) {
                  font-size: ${theme.font.size.s};
                  line-height: ${theme.font.lineHeight.xxl};
                  margin: 0.75em 0;
                }
                :global(ul) {
                  list-style: circle;
                  margin: 0;
                  padding: 0;
                }
                :global(li) {
                  margin: 0.7em 1em;
                  line-height: 1.5;
                }
                :global(a) {
                  font-weight: ${theme.font.weight.bold};
                  color: ${theme.color.brand.primary};
                  text-decoration: underline;
                }
                :global(a.gatsby-resp-image-link) {
                  border: 0;
                  display: block;
                  margin: 2.5em 0;
                  border-radius: ${theme.size.radius.default};
                  overflow: hidden;
                  border: 1px solid ${theme.line.color};
                }
                :global(code.language-text) {
                  background: ${theme.color.neutral.gray.c};
                  text-shadow: none;
                  color: inherit;
                  padding: 0.1em 0.3em 0.2em;
                  border-radius: 0.1em;
                }
              }

              @keyframes bodytextEntry {
                from {
                  opacity: 0;
                }
                to {
                  opacity: 1;
                }
              }
            `}</style>
          </Article>
        )}
      </ThemeContext.Consumer>

      <Seo data={page} facebook={{}} />
    </React.Fragment>
  );
};

TadigTemplate.propTypes = {
  data: PropTypes.object.isRequired
};

export default TadigTemplate;

//eslint-disable-next-line no-undef
export const tadigQuery = graphql`
  query DataByTadig($tadig: String!) {
    allE212Csv(filter: { Network_code: { eq: $tadig } }) {
      nodes {
        Region
        Network_name
        E212_MNC
        E212_MCC
        Country_code__ISO_CC3_
      }
    }
    allTs25ExtractCsv(filter: { TADIG_code: { eq: $tadig } }) {
      nodes {
        Country_Initials
        Network
        Net {
          _Type
        }
        MNC
        MCC
      }
    }
  }
`;
